/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AssignmentId } from '../models/AssignmentId';
import type { ObuCode } from '../models/ObuCode';
import type { ObuConfig } from '../models/ObuConfig';
import type { ObuFuelBottomHmh } from '../models/ObuFuelBottomHmh';
import type { ObuFuelCalibration } from '../models/ObuFuelCalibration';
import type { ObuFuelCalibrationDate } from '../models/ObuFuelCalibrationDate';
import type { ObuFuelCalibrationPoints } from '../models/ObuFuelCalibrationPoints';
import type { ObuFuelNominalLength } from '../models/ObuFuelNominalLength';
import type { ObuFuelTankNo } from '../models/ObuFuelTankNo';
import type { ObuFuelTopHmh } from '../models/ObuFuelTopHmh';
import type { ObuFuelVolume } from '../models/ObuFuelVolume';
import type { ObuFuelWorkingLength } from '../models/ObuFuelWorkingLength';
import type { ObuSince } from '../models/ObuSince';
import type { ObuSinceUntil } from '../models/ObuSinceUntil';
import type { ObuUntil } from '../models/ObuUntil';
import type { VehicleId } from '../models/VehicleId';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ObuService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Register
   * Register OBU
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public obuRegister(requestBody: {
    code: ObuCode;
    /**
     * Notes
     */
    note?: string | null;
    type: number;
    upgradeable?: boolean;
  }): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/obu/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Vehicle assignment update
   * Vehicle assignment update
   * @param assignmentId Assignment ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public obuAssignVehicleUpdate(
    assignmentId: string,
    requestBody: ObuSinceUntil,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/obu/assign_vehicle/{assignment_id}',
      path: {
        assignment_id: assignmentId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Assignment doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get OBU Modifiers
   * Get OBU Modifiers
   * @param code OBU serial number
   * @returns any Modifiers
   * @throws ApiError
   */
  public obuGetModifiers(code: string): Observable<{
    modifiers: Array<any> | null;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/obu/{code}/modifiers',
      path: {
        code: code,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `OBU doesn't exist`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add fuel setting to OBU
   * Add fuel setting to OBU, if an old fuel exists it will be depreciated
   * @param code OBU serial number
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public obuFuelUpdate(
    code: string,
    requestBody: ObuFuelTankNo &
      ObuFuelVolume &
      ObuFuelCalibrationPoints &
      ObuFuelCalibration & {
        /**
         * Since when is this valid
         */
        since?: string;
      } & {
        /**
         * Tank no.
         */
        tank_no: number;
        /**
         * Tank volume
         */
        volume: number;
        /**
         * Number of calibration points
         */
        points: number;
        /**
         * Calibration points [[mm,L], [mm,L]]
         */
        calibration: Array<Array<number>>;
      },
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/obu/{code}/fuel/cfg',
      path: {
        code: code,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `OBU doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get OBU fuel setting
   * Get OBU fuel setting, CFG & FACT
   * @param code OBU serial number
   * @returns any OK
   * @throws ApiError
   */
  public obuFuel(code: string): Observable<
    Array<
      ObuFuelTankNo &
        ObuFuelVolume &
        ObuFuelCalibrationPoints &
        ObuFuelCalibration &
        ObuFuelNominalLength &
        ObuFuelWorkingLength &
        ObuFuelTopHmh &
        ObuFuelBottomHmh &
        ObuFuelCalibrationDate & {
          /**
           * Tank no.
           */
          tank_no: number;
          /**
           * Tank volume
           */
          volume: number;
          /**
           * Number of calibration points
           */
          points: number;
          /**
           * Calibration points [[mm,L], [mm,L]]
           */
          calibration: Array<Array<number>>;
        }
    >
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/obu/{code}/fuel/',
      path: {
        code: code,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `OBU doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get OBU configuration
   * Get OBU configuration
   * @param code OBU serial number
   * @returns any Configuration
   * @throws ApiError
   */
  public obuGetConfig(code: string): Observable<{
    /**
     * FW version of config
     */
    fw: string;
    config: any;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/obu/{code}/config',
      path: {
        code: code,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `OBU doesn't exist`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set OBU configuration
   * Set OBU configuration
   * @param code OBU serial number
   * @param requestBody
   * @returns any Saved
   * @throws ApiError
   */
  public obuSetConfig(code: string, requestBody: ObuConfig): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/obu/{code}/config',
      path: {
        code: code,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `OBU doesn't exist`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign OBU to the vehicle
   * Assign OBU to the vehicle
   * @param code OBU serial number
   * @param vehicleId Vehicle ID
   * @param requestBody
   * @returns any OBU successfully assigned
   * @throws ApiError
   */
  public obuAssign(
    code: string,
    vehicleId: string,
    requestBody: ObuSinceUntil,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/obu/{code}/assign_vehicle/{vehicle_id}',
      path: {
        code: code,
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Vehicle/OBU doesn't exist`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get latest OBU assignment
   * Get latest OBU assignment
   * @param code OBU serial number
   * @returns any Assignments
   * @throws ApiError
   */
  public obuAssignGet(code: string): Observable<
    {
      id?: AssignmentId;
      vehicle_id?: VehicleId;
      created_at?: string;
    } & ObuSince &
      ObuUntil & {
        id: AssignmentId;
        vehicle_id: VehicleId;
      }
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/obu/{code}/assign_vehicle/',
      path: {
        code: code,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Vehicle/OBU doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete
   * Delete OBU
   * @param code OBU serial number
   * @returns any Deleted
   * @throws ApiError
   */
  public obuDelete(code: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/obu/{code}/',
      path: {
        code: code,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `OBU doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
