/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EndDate } from '../models/EndDate';
import type { EventId } from '../models/EventId';
import type { EventNote } from '../models/EventNote';
import type { FuelCurrency } from '../models/FuelCurrency';
import type { FuelCurrencyDefault } from '../models/FuelCurrencyDefault';
import type { FuelLossTolerance } from '../models/FuelLossTolerance';
import type { FuelReceipt } from '../models/FuelReceipt';
import type { FuelReceiptBase } from '../models/FuelReceiptBase';
import type { FuelReceiptId } from '../models/FuelReceiptId';
import type { RefuelingTolerance } from '../models/RefuelingTolerance';
import type { StartDate } from '../models/StartDate';
import type { VehicleId } from '../models/VehicleId';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RefuelingService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Edit fuel receipt
   * Edit fuel receipt
   * @param receiptId Fuel receipt ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public editFuelReceipt(
    receiptId: string,
    requestBody: FuelReceipt,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/refueling/receipt/{receipt_id}',
      path: {
        receipt_id: receiptId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid receipt ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete fuel receipt
   * Delete fuel receipt
   * @param receiptId Fuel receipt ID
   * @returns any Deleted
   * @throws ApiError
   */
  public deleteFuelReceipt(receiptId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/refueling/receipt/{receipt_id}',
      path: {
        receipt_id: receiptId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid receipt ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign fuel receipt
   * Assign fuel receipt to a refueling event
   * @param eventId Event ID
   * @param requestBody
   * @returns any Receipt saved
   * @throws ApiError
   */
  public assignFuelReceipt(
    eventId: string,
    requestBody: FuelReceiptBase &
      FuelCurrencyDefault &
      EventNote & {
        /**
         * Fuel receipt date
         */
        date: string;
        amount: number;
      },
  ): Observable<{
    id: FuelReceiptId;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/refueling/assign/{event_id}',
      path: {
        event_id: eventId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid event`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Export fuel receipts
   * Export fuel receipts
   * @param startDate
   * @param type
   * @param vehicleId
   * @param endDate
   * @param format
   * @returns binary OK
   * @throws ApiError
   */
  public exportFuelReceipts(
    startDate: StartDate,
    type: 'xlsx' | 'csv',
    vehicleId?: VehicleId,
    endDate?: EndDate,
    format?: 'autoplan',
  ): Observable<Blob> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/refueling/{vehicle_id}/export',
      path: {
        vehicle_id: vehicleId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        type: type,
        format: format,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Refueling
   * Get refueling & fuel loss events with receipts
   * @param vehicleId
   * @param startDate
   * @param fuelLossTolerance
   * @param refuelingTolerance
   * @param endDate
   * @returns any OK
   * @throws ApiError
   */
  public listRefueling(
    vehicleId: VehicleId,
    startDate: StartDate,
    fuelLossTolerance: FuelLossTolerance,
    refuelingTolerance: RefuelingTolerance,
    endDate?: EndDate,
  ): Observable<
    Array<
      {
        id: EventId | null;
        vehicle_id: VehicleId;
        type: 'refueling' | 'fuel_loss' | 'refueling_can' | 'fuel_loss_can';
        value: number | null;
        start_at: string | null;
        end_at: string | null;
        receipt:
          | (FuelReceiptId &
              FuelReceiptBase &
              FuelCurrency &
              EventNote & {
                /**
                 * Currency symbol
                 */
                currency_symbol?: string | null;
              } & {
                /**
                 * Fuel receipt date
                 */
                date: string;
                amount: number;
                price: number | null;
                /**
                 * Currency code (ISO 4217)
                 */
                currency: string | null;
                /**
                 * Note
                 */
                note: string | null;
                /**
                 * Currency symbol
                 */
                currency_symbol: string | null;
              })
          | null;
      } & EventNote
    >
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/refueling/{vehicle_id}/',
      path: {
        vehicle_id: vehicleId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        fuel_loss_tolerance: fuelLossTolerance,
        refueling_tolerance: refuelingTolerance,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add fuel receipt
   * Add fuel receipt when refueling event not detected
   * @param vehicleId
   * @param requestBody
   * @returns any Receipt saved
   * @throws ApiError
   */
  public addFuelReceipt(
    vehicleId: VehicleId,
    requestBody: FuelReceiptBase &
      FuelCurrencyDefault &
      EventNote & {
        /**
         * Fuel receipt date
         */
        date: string;
        amount: number;
      },
  ): Observable<{
    id: FuelReceiptId;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/refueling/{vehicle_id}/',
      path: {
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
