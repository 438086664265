/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EndDate } from '../models/EndDate';
import type { EventFlagsObject } from '../models/EventFlagsObject';
import type { FuelLossTolerance } from '../models/FuelLossTolerance';
import type { IdleTolerance } from '../models/IdleTolerance';
import type { RefuelingTolerance } from '../models/RefuelingTolerance';
import type { StartDate } from '../models/StartDate';
import type { TripDetail } from '../models/TripDetail';
import type { TripDuration } from '../models/TripDuration';
import type { TripNoTrip } from '../models/TripNoTrip';
import type { UserId } from '../models/UserId';
import type { VehicleId } from '../models/VehicleId';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class TripsService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Export trips
   * Export vehicle trips
   * @param startDate
   * @param type
   * @param vehicleId
   * @param operatorId
   * @param endDate
   * @returns binary OK
   * @throws ApiError
   */
  public tripsExport(
    startDate: StartDate,
    type: 'xlsx' | 'csv' | 'pdf' | 'autoplan',
    vehicleId?: VehicleId,
    operatorId?: UserId,
    endDate?: EndDate,
  ): Observable<Blob> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/trips/export',
      query: {
        vehicle_id: vehicleId,
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
        type: type,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get trips
   * Get trips
   * ```
   * request.start-------------------------------request.end
   * trip.start----------------trip.end      trip.start-----------------trip.end
   * ```
   *
   * @param tripDuration
   * @param idleTolerance
   * @param fuelLossTolerance
   * @param refuelingTolerance
   * @param vehicleId
   * @param operatorId
   * @param startDate
   * @param endDate
   * @returns TripNoTrip OK
   * @throws ApiError
   */
  public trips(
    tripDuration: TripDuration,
    idleTolerance: IdleTolerance,
    fuelLossTolerance: FuelLossTolerance,
    refuelingTolerance: RefuelingTolerance,
    vehicleId?: VehicleId,
    operatorId?: UserId,
    startDate?: StartDate,
    endDate?: EndDate,
  ): Observable<Array<TripNoTrip>> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/trips/',
      query: {
        vehicle_id: vehicleId,
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
        trip_duration: tripDuration,
        idle_tolerance: idleTolerance,
        fuel_loss_tolerance: fuelLossTolerance,
        refueling_tolerance: refuelingTolerance,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Vehicle/operator ID doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get single trip
   * Get single trip information
   * @param tripId Trip ID
   * @param idleTolerance
   * @param fuelLossTolerance
   * @param refuelingTolerance
   * @returns TripDetail OK
   * @throws ApiError
   */
  public trip(
    tripId: string,
    idleTolerance: IdleTolerance,
    fuelLossTolerance: FuelLossTolerance,
    refuelingTolerance: RefuelingTolerance,
  ): Observable<TripDetail> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/trips/{trip_id}',
      path: {
        trip_id: tripId,
      },
      query: {
        idle_tolerance: idleTolerance,
        fuel_loss_tolerance: fuelLossTolerance,
        refueling_tolerance: refuelingTolerance,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Vehicle/operator ID doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set trip flags
   * Set trip flags
   * @param tripId Trip ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public tripFlags(
    tripId: string,
    requestBody: EventFlagsObject,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/trips/{trip_id}',
      path: {
        trip_id: tripId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Trip ID doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
